import React, { useState } from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';

import 'bootstrap/dist/css/bootstrap.min.css';

import Home from './pages/Home';
import Login from './pages/Login';
import Dashboard from './pages/Dashboard';


const App = () => {
  const [loggedInUser, setLoggedInUser] = useState('');

  return (
    <>
      <Router>
        <div>
          <Routes>
            <Route path="/" element={<Home />} />
            <Route
              path="/login"
              element={<Login setLoggedInUser={setLoggedInUser} />}
            />
            <Route
              path="/dashboard"
              element={
                loggedInUser ? (
                  <div>
                    <Dashboard loggedInUser={loggedInUser} />
                  </div>
                ) : (
                  <Navigate to="/login" />
                )
              }
            />
          </Routes>
        </div>
      </Router>
    </>
  );
};

export default App;
