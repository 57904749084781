import { Link } from 'react-router-dom';
import firebase from 'firebase/compat/app';

const AccessDenied = ({ setLoggedInUser }) => {
  return (
    <div>
      <h1>Access Denied</h1>
      <Link to="/dashboard" style={{ color: "grey" }}>
        &#8594; Go back to Dashboard
      </Link>
      <br />
      <Link
        to="/login"
        style={{ color: "grey" }}
        onClick={() => firebase.auth().signOut()}
      >
        &#8594; Back to Login
      </Link>
    </div>
  );
};

export default AccessDenied;
