import { Link } from "react-router-dom";

import firebase from "firebase/compat/app";
import "firebase/compat/auth";

import logo from '../assets/img/whitelogonobg.webp';
import "../assets/TopBar.css";

const TopBar = ({ orgName }) => {
  return (
    <div className="topBarComponent row m-0">
      <div className="resturantName col-10"><img width={"35px"} src={logo} alt="logo" /><b>Drilldown</b>/<i><u>{orgName}</u></i></div>
      <Link
        to="/login"
        className="signOutButton col-2"
        style={{ fontSize: "12px" }}
        onClick={() => firebase.auth().signOut()}
      >
        <button>
          Sign Out <i className="fa fa-sign-out"></i>
        </button>
      </Link>
    </div>
  );
};

export default TopBar;
