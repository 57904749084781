import React, { useState } from "react";
import { useNavigate } from "react-router-dom";

import firebase from "firebase/compat/app";
import "firebase/compat/auth";

import image from '../assets/img/login.webp';
import "../assets/Login.css";

const Login = ({ setLoggedInUser }) => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const navigate = useNavigate();

  const handleLogin = (e) => {
    e.preventDefault();
    // Implement Firebase authentication here
    firebase
      .auth()
      .signInWithEmailAndPassword(email, password)
      .then((userCredential) => {
        const loggedInUser = userCredential.user.uid;
        setLoggedInUser(loggedInUser);
        // Track successful login event
        window.gtag("event", "login", {
          event_category: "email/password",
          event_label: "logged_in",
        });
        navigate("/dashboard");
      })
      .catch((error) => {
        // Track login failed event
        window.gtag("event", "login_failed", {
          event_category: "email/password",
          event_label: error.message,
        });
        // Handle login error
        setErrorMessage(error.message);
        console.error("Login Error:", error);
      });
  };

  const toggleShowPassword = () => {
    setShowPassword((prevShowPassword) => !prevShowPassword);
  };

  return (
    <div className="loginPage row justify-content-center align-items-center m-0">
      <div className="col-10 p-0 m-0">
        <div className="loginBox row">
          <div
            className="col-lg-8 col-md-8 col-sm-12"
            style={{ overflow: "hidden" }}
          >
            <img src={image} alt="Login" />
          </div>
          <div
            className="col-lg-4 col-md-4 col-sm-10 align-self-center"
            style={{ paddingLeft: "2vw" }}
          >
            <div className="row justify-content-start align-items-center">
              <form onSubmit={handleLogin}>
                <h1 className="logintitle col">Login</h1>
                <input
                  className="col"
                  type="email"
                  placeholder="Email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
                <input
                  className="col"
                  type={showPassword ? "text" : "password"}
                  placeholder="Password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                />
                <button
                  className="col"
                  onClick={toggleShowPassword}
                  style={{
                    fontSize: "65%",
                    width: "50px",
                    height: "20px",
                    margin: "0px 5px",
                    background: "none",
                    color: "#2B4060",
                    border: "0px",
                  }}
                >
                  {showPassword ? "Hide" : "Show"}
                </button>
                <div />
                {errorMessage ? (
                  <p
                    className="text-danger"
                    style={{
                      fontSize: "100%",
                      marginBottom: "-1%",
                      cursor: "pointer",
                    }}
                  >
                    {errorMessage.split(":")[1]}
                  </p>
                ) : (
                  ""
                )}
                <button className="col" onClick={handleLogin}>
                  Login &#8594;
                </button>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;
