import React, { useState } from "react";
import database from "../FirebaseConfig";
import AccessDenied from "./AccessDenied";

const CreateTeam = ({ data, orgId, role, loggedInUser }) => {
  const [teamName, setTeamName] = useState("");
  const [assignedEmployees, setAssignedEmployees] = useState({});
  const [assignedManagers, setAssignedManagers] = useState({});

  if (loggedInUser !== orgId || role !== "org") {
    return <AccessDenied />;
  }

  const handleAssignManager = (e) => {
    const selectedManagerId = e.target.value;
    const selectedManagerName = data.manager[selectedManagerId];

    setAssignedManagers((prevManagers) => {
      const updatedManagers = { ...prevManagers };

      if (updatedManagers[selectedManagerId]) {
        // Manager already exists, so remove it
        delete updatedManagers[selectedManagerId];
      } else {
        // Manager does not exist, so add it
        updatedManagers[selectedManagerId] = selectedManagerName;
      }

      return updatedManagers;
    });
  };

  const handleAssignEmployee = (e) => {
    const selectedEmployeeId = e.target.value;
    const selectedEmployeeName = data.employee[selectedEmployeeId];

    setAssignedEmployees((prevEmployees) => {
      const updatedEmployees = { ...prevEmployees };

      if (updatedEmployees[selectedEmployeeId]) {
        // Employee already exists, so remove it
        delete updatedEmployees[selectedEmployeeId];
      } else {
        // Employee does not exist, so add it
        updatedEmployees[selectedEmployeeId] = selectedEmployeeName;
      }

      return updatedEmployees;
    });
  };

  const handleCreateTask = async (e) => {
    e.preventDefault();

    const tasks = {
      employee: assignedEmployees,
      manager: assignedManagers,
    };

    if (role === "org") {
      await database.ref(`${orgId}/teams/${teamName}`).set(tasks);

      setAssignedEmployees({});
      setAssignedManagers({});
      setTeamName("");
    } else {
      alert("Access Denied");
    }
  };

  return (
    <div className="createTaskComponent">
      <h2>Create Team</h2>
      <form onSubmit={handleCreateTask}>
        <div className="form-group">
          <label htmlFor="teamTitle">Team Name:</label>
          <input
            type="text"
            id="teamTitle"
            value={teamName}
            onChange={(e) => setTeamName(e.target.value)}
          />
        </div>

        <div className="form-group">
          <label htmlFor="assignedManager">Assign Managers:</label>
          <select
            id="assignedManager"
            value={Object.keys(assignedManagers)}
            onChange={handleAssignManager}
            multiple
          >
            {Object.entries(data.manager).map(([managerId, managerName]) => (
              <option key={managerId} value={managerId}>
                {managerName}
              </option>
            ))}
          </select>
        </div>

        <div className="form-group">
          <label htmlFor="assignedEmployee">Assign Employees:</label>
          <select
            id="assignedEmployee"
            value={Object.keys(assignedEmployees)}
            onChange={handleAssignEmployee}
            multiple
          >
            {Object.entries(data.employee).map(([empId, empName]) => (
              <option key={empId} value={empId}>
                {empName}
              </option>
            ))}
          </select>
        </div>

        <button type="submit">Create Team</button>
      </form>
    </div>
  );
};

export default CreateTeam;
