import React, { useState, useEffect } from "react";
import MarkComplete from "./MarkComplete";
import CreateTask from "./CreateTask";
import "../assets/TasksBoard.css";

const TasksBoard = ({ orgId, role, data, selectedTeam, loggedInUser }) => {
  const [showMarkComplete, setShowMarkComplete] = useState(false);
  const [selectedTask, setSelectedTask] = useState(null);
  const [showCreateTask, setShowCreateTask] = useState(false);
  const [filter, setFilter] = useState("all"); // Default filter is "all"
  const [sortedTasks, setSortedTasks] = useState([]);

  useEffect(() => {
    if (!selectedTeam || !data || !data.teams || !data.teams[selectedTeam]) {
      return;
    }

    const teamData = data.teams[selectedTeam];
    let { tasks } = teamData;

    if (tasks === undefined || tasks === null) {
      tasks = {
        arqyEpcp4HQ71Ig5B6hq6EMnHwD3_2_4_2024__1_36_03_am: {
          assigned_to: ["None"],
          created_by: "None",
          end_time: "None",
          report: "",
          report_description: "",
          start_time: "None",
          status: "None",
          task_id: "None",
          title: "No Tasks",
        },
      };
    }

    let filteredTasks = Object.values(tasks).filter((task) => {
      if (filter === "all") {
        return true;
      } else if (filter === "assigned") {
        return task.assigned_to.includes(loggedInUser);
      } else if (filter === "pending") {
        return task.status === "pending";
      } else if (filter === "pastDue") {
        const currentTime = new Date();
        const endTime = new Date(task.end_time);
        return task.status !== "completed" && endTime < currentTime;
      } else if (filter === "completed") {
        return task.status === "completed";
      }
      return true;
    });

    // Sort tasks by deadline timestamp
    filteredTasks.sort((a, b) => {
      const deadlineA = new Date(a.end_time);
      const deadlineB = new Date(b.end_time);
      return deadlineA - deadlineB;
    });

    setSortedTasks(filteredTasks);
  }, [selectedTeam, data, filter, loggedInUser]);

  const handleMarkComplete = (task) => {
    setSelectedTask(task);
    setShowMarkComplete(true);
  };

  const handleCloseMarkComplete = () => {
    setSelectedTask(null);
    setShowMarkComplete(false);
  };

  const handleCreateTask = () => {
    setShowCreateTask(true);
  };

  const handleCloseCreateTask = () => {
    setShowCreateTask(false);
  };

  return (
    <div className="tasksBoardComponent">
      <h1>{selectedTeam} Tasks</h1>
      <div className="filtersRow">
        <button className="filters" onClick={() => setFilter("all")}>
          All
        </button>
        <button className="filters" onClick={() => setFilter("assigned")}>
          Assigned
        </button>
        <button className="filters" onClick={() => setFilter("pending")}>
          Pending
        </button>
        <button className="filters" onClick={() => setFilter("pastDue")}>
          Past Due
        </button>
        <button className="filters" onClick={() => setFilter("completed")}>
          Completed
        </button>
      </div>
      <div className="Cards">
        {(role === "org" || role === "mng") && selectedTeam ? (
          <div className="createTaskCard" onClick={() => handleCreateTask()}>
            +
          </div>
        ) : (
          ""
        )}
        {sortedTasks &&
          sortedTasks.map((task, index) => (
            <div
              className={`Card ${
                task.report === "no"
                  ? "text-bg-danger text-danger-emphasis"
                  : task.report === "yes"
                  ? "text-bg-success text-success-emphasis"
                  : ""
              }`}
              key={index}
            >
              <h5>
                Task: {task.title}
              </h5>
              {task.report !== "" && task.report_description !== "" ? (
                <>
                  Report: <strong>{task.report}</strong>
                  <br />
                  Report Details: <strong>{task.report_description}</strong>
                </>
              ) : (
                <></>
              )}
              <p>
                Status: <strong>{task.status}</strong>
                <br />
                Deadline: <strong>{task.end_time}</strong>
              </p>
              <br />
              {task.assigned_to.includes(loggedInUser) &&
              task.status !== "completed" ? (
                <button
                  className="markCompleteButton"
                  onClick={() => handleMarkComplete(task)}
                >
                  Mark Complete
                </button>
              ) : (
                ""
              )}
            </div>
          ))}
      </div>
      {showMarkComplete && (
        <div className="markCompleteOverlay">
          <div className="markCompleteModal">
            <button className="closeButton" onClick={handleCloseMarkComplete}>
              x
            </button>
            <MarkComplete
              task={selectedTask}
              selectedTeam={selectedTeam}
              orgId={orgId}
              loggedInUser={loggedInUser}
              onClose={handleCloseMarkComplete}
            />
          </div>
        </div>
      )}
      {showCreateTask && (
        <div className="createTaskOverlay">
          <div className="createTaskModal">
            <button className="closeButton" onClick={handleCloseCreateTask}>
              x
            </button>
            <CreateTask
              data={data}
              orgId={orgId}
              role={role}
              loggedInUser={loggedInUser}
              selectedTeam={selectedTeam}
              onClose={handleCloseCreateTask}
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default TasksBoard;
