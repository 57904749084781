import { useState, useEffect } from "react";
import database from "../FirebaseConfig";
import TopBar from "../components/TopBar";
import TasksBoard from "../components/TasksBoard";
import CreateTeam from "../components/CreateTeam";
import AccessDenied from "../components/AccessDenied";

import logo from "../assets/img/blackLogoNoBg.svg";
import open from "../assets/img/open.svg";
import close from "../assets/img/close.svg";
import "../assets/Dashboard.css";

const Dashboard = ({ loggedInUser }) => {
  const [orgId, setOrgId] = useState("");
  // const [orgName, setOrgName] = useState("");
  const [role, setRole] = useState("");
  const [data, setData] = useState(null);
  const [sidebarOpen, setSidebarOpen] = useState(true); // State for sidebar
  const [teamNames, setTeamNames] = useState([]);
  const [selectedTeam, setSelectedTeam] = useState(null);
  //State to manage the visibility of the CreateTeam overlay
  const [showCreateTeam, setShowCreateTeam] = useState(false);

  useEffect(() => {
    const fetchOrg = async () => {
      try {
        const accessSnapshot = await database
          .ref(`mapping/${loggedInUser}`)
          .once("value");

        if (accessSnapshot.val()) {
          const access = accessSnapshot.val();
          const fetchedOrgId = access["org"];
          const fetchedRole = access["role"];
          const orgNameSnapshot = await database
            .ref(`${fetchedOrgId}`)
            .once("value");
          const fetchedData = orgNameSnapshot.val();

          // Determine teams based on role
          if (fetchedRole === "org") {
            setTeamNames(Object.keys(fetchedData.teams));
          } else {
            // If role is "mng" or "emp", get teams they are part of
            let userTeams = [];
            Object.entries(fetchedData.teams).forEach(
              ([teamName, teamData]) => {
                if (
                  teamData.manager[loggedInUser] ||
                  teamData.employee[loggedInUser]
                ) {
                  userTeams.push(teamName);
                }
              }
            );
            setTeamNames(userTeams);
          }

          setOrgId(fetchedOrgId);
          setRole(fetchedRole);
          setData(fetchedData);
        } else {
          // Handle access denied
          <AccessDenied />;
          setData(null);
        }
      } catch (error) {
        console.log(error.message);
      }
    };

    fetchOrg();
  }, [loggedInUser]); // Only run the effect when loggedInUser changes

  const handleCardClick = (teamName) => {
    setSelectedTeam(teamName);
    setShowCreateTeam(false); // Close the CreateTeam
    setSidebarOpen(false);
  };

  const toggleSidebar = () => {
    setSidebarOpen(!sidebarOpen);
  };

  const handleCreateTeam = () => {
    setShowCreateTeam(true); // Show the CreateTeam
    setSelectedTeam(null);
  };

  const handleCloseCreateTeam = () => {
    setShowCreateTeam(false); // Close the CreateTeam
  };

  return (
    <>
      {data && <TopBar orgName={data.orgName} />}
      <div className="dashboard-page p-0 m-0">
        <div
          className={
            sidebarOpen ? "dashboard-sidebar" : "dashboard-sidebar-collapsed"
          }
        >
          <h4 style={{textAlign: 'center'}}>Teams</h4>
          {teamNames.map((teamName, index) => (
            <div key={index} onClick={() => handleCardClick(teamName)}>
              <div className="teamCard">
                <div className="teamCardTitle">{teamName}</div>
              </div>
            </div>
          ))}
          {role === "org" ? (
            <div className="createTeamCard" onClick={() => handleCreateTeam()}>
              +
            </div>
          ) : (
            ""
          )}
        </div>
        <div className="dashboard-sidebar-toggler" onClick={toggleSidebar}>
          {sidebarOpen ? (
            <img src={close} alt="open" />
          ) : (
            <img src={open} alt="open" />
          )}
        </div>
        <div
          className={
            sidebarOpen ? "dashboard-fullscreen" : "dashboard-fullscreen-expand"
          }
        >
          <TasksBoard
            orgId={orgId}
            role={role}
            data={data}
            selectedTeam={selectedTeam}
            loggedInUser={loggedInUser}
          />
          {showCreateTeam && (
            <div className="createTeamOverlay">
              <div className="createTeamModal">
                <button className="closeButton" onClick={handleCloseCreateTeam}>
                  Close
                </button>
                <CreateTeam
                  data={data}
                  orgId={orgId}
                  role={role}
                  loggedInUser={loggedInUser}
                  onClose={handleCloseCreateTeam}
                />
              </div>
            </div>
          )}
          <div className="dashboardFooter">
            <small>&copy;drilldown.online</small>&nbsp;
            <img src={logo} alt="logo" width={"18px"} />
          </div>
        </div>
      </div>
    </>
  );
};

export default Dashboard;
