import React from "react";
import { Link, useNavigate } from "react-router-dom";
import "react-bootstrap";

import logo from "../assets/img/whitelogonobg.webp";
import mockUp from "../assets/img/mockUp.webp";
import SaleMockUp from "../assets/img/sales.webp";
// import ITT from "../assets/img/ITT.svg";
import PurchaseMockUp from "../assets/img/purchase.webp";
import InternalTransferMockup from "../assets/img/internaltransfer.webp";

import "../assets/Home.css";

const Home = () => {
  const navigate = useNavigate();

  return (
    <div style={{ width: "100%", overflow: "hidden", background: "#2B4060" }}>
      <section className="sec1" overflow={"hidden"}>
        <div className="topBarHome">
          <img width={"35px"} src={logo} alt="logo" />
        </div>
        <div className="row">
          <div className="col-lg-6 col-md-6 col-sm-12">
            <p className="title">Drilldown Tasks</p>
            <p className="description">
              Welcome to DrillDown Tasks, your ultimate task management gateway.
              We leverage comprehensive immersive artificial intelligence to
              empower your task management with AI-driven insights for
              continuous improvement.
            </p>
            <Link to="/login">
              <button className="start">Start Drilling</button>
            </Link>
          </div>
          <div className="col-lg-6 col-md-6 col-sm-12">
            <img className="mockup" width={"135%"} src={mockUp} alt="Mock Up" />
          </div>
        </div>
      </section>
      {/* <section className="sec2">
        <div className="row justify-content-around">
          <div className="col-lg-5 col-md-11 col-sm-12 mt-4">
            <h1 className="ITTTitle">What's Inside The Tool?</h1>
            <h5 className="ITTSubTitle">
              With DrillDown, you can access all of your reports in one
              convenient dashboard, eliminating the hassle of manual report
              downloading and data analysis.
            </h5>
            <p className="ITTSubText">
              “Our team of domain experts help you optimize by leveraging
              industry benchmarks.”
            </p>
            <button className="ITTButton">
              <a
                href="#contactus"
                style={{ textDecoration: "none", color: "#2B4060" }}
              >
                Book A Demo
              </a>
            </button>
            <div className="col-lg-12 col-md-0 col-sm-0">
              <img src={ITT} alt="Mock Up" width={"100%"} />
            </div>
          </div>
          <div className="col-lg-6 col-md-11 col-sm-12">
            <div className="row">
              <div className="col-lg-6 col-md-6 col-sm-12">
                <div className="ITTcard">
                  <h5 className="ITTcardTitle">
                    Enhance Your Business with Precision
                  </h5>
                  <p className="ITTcardText">
                    At DrillDown, we are your partners in progress, dedicated to
                    fuelling your business with data-driven insights.
                  </p>
                </div>
                <div className="ITTcard">
                  <h5 className="ITTcardTitle">Streamlined Efficiency</h5>
                  <p className="ITTcardText">
                    Experience a seamless journey through our user-friendly
                    interface. We're here to simplify complex processes and
                    streamline tasks for maximum efficiency, giving your
                    business the step up it deserves.
                  </p>
                </div>
              </div>
              <div className="col-lg-6 col-md-6 col-sm-12">
                <div className="ITTcard" style={{ marginTop: "7vw" }}>
                  <h5 className="ITTcardTitle">Unleash the Power of Data</h5>
                  <p className="ITTcardText">
                    Our detailed visualizations provide a comprehensive overview
                    of your business performance. Say goodbye to guesswork and
                    hello to informed decisions made with clarity.
                  </p>
                </div>
                <div className="ITTcard">
                  <h5 className="ITTcardTitle">
                    Tailored Feedback Forms Made Easy
                  </h5>
                  <p className="ITTcardText">
                    Crafting customized feedback forms has never been simpler.
                    With our intuitive tools, you can effortlessly gather
                    precise insights from your customers and stakeholders.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section> */}
      <section className="sec3">
        <div className="row align-items-center justify-content-center pt-5 pb-5 ITR">
          <h1 className="mt-5 mb-5">Explore Drilldown.Online Tool</h1>
          <div className="col-lg-4 col-md-12 col-sm-12">
            <div className="row align-items-start justify-content-center ITRCard">
              <div className="col-lg-11 col-md-4 col-sm-12">
                <img src={SaleMockUp} alt="Mock Up" width={"100%"} />
              </div>
              <div className="col-lg-11 col-md-6 col-sm-12">
                <h5>Sales Performance Dashboard</h5>
                <p>
                  Gain comprehensive insights into restaurant sales, tracking
                  overall performance and specific sales trends. Monitor
                  departmental and staff contributions, empowering informed
                  decision-making for optimizing revenue and enhancing
                  operational efficiency.
                </p>
              </div>
            </div>
          </div>
          <div className="col-lg-4 col-md-12 col-sm-12">
            <div className="row align-items-start justify-content-center ITRCard">
              <div className="col-lg-11 col-md-4 col-sm-12">
                <img src={PurchaseMockUp} alt="Mock Up" width={"100%"} />
              </div>
              <div className="col-lg-11 col-md-6 col-sm-12">
                <h5>Purchase Trends Analyser</h5>
                <p>
                  Explore detailed purchase reports and trends, facilitating
                  easy navigation and verification of transactions. Efficiently
                  identify specific purchases and discern buying patterns,
                  aiding the restaurant owner in managing inventory, controlling
                  costs, and ensuring procurement accuracy.
                </p>
              </div>
            </div>
          </div>
          <div className="col-lg-4 col-md-12 col-sm-12">
            <div className="row align-items-start justify-content-center ITRCard">
              <div className="col-lg-11 col-md-4 col-sm-12">
                <img
                  src={InternalTransferMockup}
                  alt="Mock Up"
                  width={"100%"}
                />
              </div>
              <div className="col-lg-11 col-md-6 col-sm-12">
                <h5>Consumption Optimization</h5>
                <p>
                  Assess internal material usage within the restaurant's
                  departments, optimizing cost-efficiency and minimizing
                  wastage. Visualize patterns of raw material consumption to
                  make data-driven decisions that lead to cost savings, improved
                  sustainability, and effective resource management.
                </p>
              </div>
            </div>
          </div>
          <a className="drilldownButton" href={"https://drilldown.online"}>
            Explore Drilldown &rarr;
          </a>
        </div>
      </section>

      <section className="contactus" id="contactus">
        <button className="teamButton" onClick={() => navigate("/teampage")}>
          Meet The Team &rarr;
        </button>
        <p className="joinus">
          Join DrillDown today and let data lead you to success!
        </p>
        <p className="email">
          Start Drilling Now, drop a mail at&nbsp;
          <a href="mailto: start@drilldown.online">start@drilldown.online</a>
        </p>
      </section>
    </div>
  );
};

export default Home;
