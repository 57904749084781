import React, { useState } from "react";
import database from "../FirebaseConfig";

const CreateTask = ({ data, orgId, role, loggedInUser, selectedTeam }) => {
  const [assignedEmployees, setAssignedEmployees] = useState([]);
  const [taskTitle, setTaskTitle] = useState("");
  const [deadlineDate, setDeadlineDate] = useState("");
  const [deadlineTime, setDeadlineTime] = useState("");

  console.log(data.teams[selectedTeam].employee);

  const handleAssignEmployee = (employeeId) => {
    if (!assignedEmployees.includes(employeeId)) {
      // Employee is not assigned, add to the list
      setAssignedEmployees([...assignedEmployees, employeeId]);
    } else {
      // Employee is already assigned, remove from the list
      const updatedEmployees = assignedEmployees.filter(
        (emp) => emp !== employeeId
      );
      setAssignedEmployees(updatedEmployees);
    }
  };

  const handleCreateTask = async (e) => {
    e.preventDefault();

    const start_time = new Date().toLocaleString();
    const start_time_key = start_time
      .replace("/", "_")
      .replace("/", "_")
      .replace(",", "_")
      .replace(":", "_")
      .replace(":", "_")
      .replace(" ", "_")
      .replace(" ", "_");

    const task = {
      assigned_to: assignedEmployees,
      created_by: loggedInUser,
      end_time: `${deadlineDate} ${deadlineTime}`,
      report: "",
      report_description: "",
      start_time: start_time,
      status: "pending",
      task_id: `${loggedInUser}_${start_time_key}`,
      title: taskTitle
    };

    if (role === "mng" || role === "org") {
      await database
        .ref(
          `${orgId}/teams/${selectedTeam}/tasks/${loggedInUser}_${start_time_key}`
        )
        .set(task);

      setAssignedEmployees([]);
      setTaskTitle("");
      setDeadlineDate("");
      setDeadlineTime("");
    } else {
      alert("Access Denied");
    }
  };

  return (
    <div className="createTaskComponent">
      <h2>Create Task</h2>
      <form onSubmit={handleCreateTask}>
        <div className="form-group">
          <label htmlFor="taskTitle">Title:</label>
          <input
            type="text"
            id="taskTitle"
            value={taskTitle}
            onChange={(e) => setTaskTitle(e.target.value)}
            required
          />
        </div>
        <div className="form-group">
          <label htmlFor="deadlineDate">Deadline Date:</label>
          <input
            type="date"
            id="deadlineDate"
            value={deadlineDate}
            onChange={(e) => setDeadlineDate(e.target.value)}
            required
          />
        </div>
        <div className="form-group">
          <label htmlFor="deadlineTime">Deadline Time:</label>
          <input
            type="time"
            id="deadlineTime"
            value={deadlineTime}
            onChange={(e) => setDeadlineTime(e.target.value)}
            required
          />
        </div>
        <div className="form-group">
          <label htmlFor="assignedEmployee">Assign To:</label>
          <select
            id="assignedEmployee"
            value={assignedEmployees}
            onChange={(e) => handleAssignEmployee(e.target.value)}
            multiple
            required
          >
            {/* Render dropdown options with employee names */}
            {data.teams[selectedTeam].employee &&
              Object.entries(data.teams[selectedTeam].employee).map(
                ([empId, empName]) => (
                  <option key={empId} value={empId}>
                    {empName}
                  </option>
                )
              )}
          </select>
        </div>
        <button type="submit">Create Task</button>
      </form>
    </div>
  );
};

export default CreateTask;
